// Generated by Framer (7f69244)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["EeXuko5uo", "LLngPDzKf"];

const serializationHash = "framer-l8Bdz"

const variantClassNames = {EeXuko5uo: "framer-v-hu9xgz", LLngPDzKf: "framer-v-rk5hbo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Closed: "EeXuko5uo", Open: "LLngPDzKf"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "EeXuko5uo"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "EeXuko5uo", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-hu9xgz", className, classNames)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"EeXuko5uo"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({LLngPDzKf: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-g1l4ko"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"h7WGDkMbe"} style={{rotate: 0}} variants={{LLngPDzKf: {rotate: -180}}}><SVG className={"framer-7vvo3y"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"oi_dflnku"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 12 11\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"rgba(1, 31, 64, 0.6)\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={10096564748} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-l8Bdz.framer-1rq0gzm, .framer-l8Bdz .framer-1rq0gzm { display: block; }", ".framer-l8Bdz.framer-hu9xgz { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-l8Bdz .framer-g1l4ko { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-l8Bdz .framer-7vvo3y { flex: none; height: 11px; left: calc(42.857142857142875% - 12px / 2); position: absolute; top: calc(35.71428571428574% - 11px / 2); width: 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LLngPDzKf":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTEatClMA4: React.ComponentType<Props> = withCSS(Component, css, "framer-l8Bdz") as typeof Component;
export default FramerTEatClMA4;

FramerTEatClMA4.displayName = "Caret";

FramerTEatClMA4.defaultProps = {height: 14, width: 14};

addPropertyControls(FramerTEatClMA4, {variant: {options: ["EeXuko5uo", "LLngPDzKf"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerTEatClMA4, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})